import { ChangeEvent, FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { fetchCompanies } from "src/api/customers.ts";
import { Customer } from "src/model/customer.ts";

interface CompanyDropdownProps {
  onChangeCompanyId: (companyId: string | undefined) => void;
  customerId: string | undefined;
  companyId: string | undefined;
  className?: string;
  size?: "sm" | "lg";
}

export const CompanyDropdown: FC<CompanyDropdownProps> = ({
  onChangeCompanyId,
  customerId,
  companyId,
  className,
  size,
}) => {
  const [companies, setCompanies] = useState<Customer[] | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (customerId !== undefined) {
      setLoading(true);
      fetchCompanies(customerId)
        .then((result) => setCompanies(result))
        .finally(() => setLoading(false));
    } else {
      setCompanies(undefined);
    }
  }, [customerId]);

  const onChangeItem = (event: ChangeEvent<HTMLSelectElement>) => {
    const companyId = event.target.value;
    if (companyId !== "") {
      onChangeCompanyId(companyId);
    } else {
      onChangeCompanyId(undefined);
    }
  };

  const buildItems = () => {
    if (customerId === undefined) {
      return <option value="">Select Customer</option>;
    }

    if (companies === undefined || loading) {
      return <option value="">Loading ...</option>;
    }

    return (
      <>
        <option value="">Select Company</option>
        {companies.map((c) => (
          <option key={`customer-${c.id}`} value={c.id}>
            {c.companyName}
          </option>
        ))}
      </>
    );
  };

  return (
    <Form.Select
      disabled={loading}
      onChange={onChangeItem}
      value={loading ? "" : companyId ?? ""}
      className={className}
      size={size}
    >
      {buildItems()}
    </Form.Select>
  );
};
