import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { Collapse, Container, Fade, ListGroup, Nav, NavDropdown, Navbar } from "react-bootstrap";
import {
  BoxArrowRight,
  CaretDownFill,
  CaretUpFill,
  Dash,
  HouseDoor,
  InfoCircle,
  List,
  Map,
  Truck,
  X,
} from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

import logo from "src/assets/iw-logo.png";
import { UserRole, clearUser } from "src/store/auth.ts";
import { useAppDispatch, useAppSelector } from "src/store/store.ts";

import styles from "./navigation.module.scss";

interface NavigationItem {
  title: string;
  url: string;
  icon?: ReactNode;
  role?: "Tracking_Admin" | "Tracking_Nothing";
}

interface NavigationWithChildren {
  title: string;
  role?: NavigationItem["role"];
  children: NavigationItem[];
}

const navigationItems: (NavigationItem | NavigationWithChildren)[] = [
  {
    title: "Home",
    url: "/",
    icon: <HouseDoor size={18} />,
    role: undefined,
  },
  {
    title: "Subscriptions",
    url: "/subscriptions",
    icon: <Truck size={18} />,
    role: undefined,
  },
  {
    title: "Containers",
    url: "/containers",
    role: undefined,
  },
  {
    title: "Map",
    url: "/map",
    icon: <Map size={16} />,
    role: undefined,
  },
  {
    title: "Admin",
    role: "Tracking_Admin",
    children: [
      {
        title: "Customers",
        url: "/customers",
        role: undefined,
      },
      {
        title: "Users",
        url: "/users",
        role: undefined,
      },
      {
        title: "Subscription Logs",
        url: "/subscription-logs",
        role: undefined,
      },
      {
        title: "Background Worker",
        url: "/jobs",
        role: undefined,
      },
    ],
  },
];

const isNavigationItemWithChildren = (
  item: NavigationItem | NavigationWithChildren
): item is NavigationWithChildren => {
  return (item as NavigationWithChildren).children !== undefined;
};

const buildNavDropdown = (ni: NavigationWithChildren, index: number, role: UserRole) => {
  return (
    <NavDropdown key={`navigation-item-${index}`} title={ni.title} id={`nav-dropdown-${ni.title}`}>
      {ni.children
        .filter((nc) => nc.role === undefined || nc.role === role)
        .map((nc, childIndex) => (
          <NavDropdown.Item key={`navigation-item-${index}-child-${childIndex}`} as={NavLink} to={nc.url}>
            {nc.title}
          </NavDropdown.Item>
        ))}
    </NavDropdown>
  );
};

const SidebarDropdown: FC<{ item: NavigationWithChildren; role: UserRole; closeSidebar: () => void }> = ({
  item,
  role,
  closeSidebar,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((open) => !open);
  };

  return (
    <>
      <ListGroup.Item
        onClick={toggleDropdown}
        className="d-flex justify-content-between align-items-center"
        role="button"
      >
        <div>{item.title}</div>
        {isOpen ? <CaretUpFill /> : <CaretDownFill />}
      </ListGroup.Item>
      <ListGroup.Item className="p-0 border-bottom-0">
        <Collapse in={isOpen}>
          <div>
            {item.children
              .filter((nc) => nc.role === undefined || nc.role === role)
              .map((nc, childIndex) => (
                <ListGroup.Item
                  as={NavLink}
                  key={`sidebar-item-child-${childIndex}`}
                  className={"d-flex align-items-center " + (childIndex === 0 ? "border-top-0" : "")}
                  to={nc.url}
                  onClick={closeSidebar}
                >
                  <Dash />
                  &nbsp;{nc.title}
                </ListGroup.Item>
              ))}
          </div>
        </Collapse>
      </ListGroup.Item>
    </>
  );
};

export const Navigation: FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const closeSidebar = useCallback(() => {
    setIsOpen(false);
  }, []);

  const navLinks = useMemo(() => {
    if (user === null || user === undefined) {
      return null;
    }
    return (
      <Nav className="d-none d-md-flex">
        {navigationItems
          .filter((ni) => ni.role === undefined || ni.role === user.role)
          .map((ni, index) => {
            if (isNavigationItemWithChildren(ni)) {
              return buildNavDropdown(ni, index, user.role);
            } else {
              return (
                <Nav.Link
                  key={`navigation-item-${index}`}
                  as={NavLink}
                  to={ni.url}
                  className="d-flex align-items-center"
                  onClick={closeSidebar}
                >
                  {ni.icon}&nbsp;&nbsp;{ni.title}
                </Nav.Link>
              );
            }
          })}
      </Nav>
    );
  }, [user, closeSidebar]);

  const sidebarLinks = useMemo(() => {
    if (user === null || user === undefined) {
      return null;
    }
    return navigationItems
      .filter((ni) => ni.role === undefined || ni.role === user.role)
      .map((ni, index) => {
        if (isNavigationItemWithChildren(ni)) {
          return (
            <SidebarDropdown item={ni} key={`sidebar-item-${index}`} role={user.role} closeSidebar={closeSidebar} />
          );
        } else {
          return (
            <ListGroup.Item as={NavLink} to={ni.url} key={`sidebar-item-${index}`} onClick={closeSidebar}>
              {ni.title}
            </ListGroup.Item>
          );
        }
      });
  }, [user, closeSidebar]);

  const logout = () => {
    dispatch(clearUser());
    closeSidebar();
  };

  return (
    <Navbar bg="primary" expand="md">
      <Container fluid={true} className="px-4">
        <Navbar.Brand as={NavLink} to="/">
          <img src={logo} alt="Your Company" width="155" height="38" />
        </Navbar.Brand>
        {navLinks}
        <div className="flex-grow-1" />
        <Nav className={`d-none d-md-flex ${styles.rightNavbar}`}>
          <Nav.Link as={NavLink} to="/about" className="justify-content-end d-flex align-items-center">
            <InfoCircle size={18} />
            &nbsp;&nbsp;About
          </Nav.Link>
          {user !== null && user !== undefined && (
            <Navbar.Text className="d-flex align-items-center ps-3" role="button">
              <BoxArrowRight onClick={logout} size={24} />
            </Navbar.Text>
          )}
        </Nav>
        <Navbar.Text className="d-md-none" role="button" onClick={() => setIsOpen(true)}>
          <List size={24} />
        </Navbar.Text>
      </Container>
      <Fade in={isOpen} unmountOnExit={true} mountOnEnter={true}>
        <div>
          <div
            className={"position-fixed top-0 end-0 bottom-0 start-0 bg-black opacity-25 z-1"}
            onClick={closeSidebar}
          />
        </div>
      </Fade>
      <Collapse
        in={isOpen}
        className="position-fixed top-0 bottom-0 end-0 bg-white d-md-none z-1"
        dimension="width"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div>
          <ListGroup style={{ width: "200px" }}>
            <ListGroup.Item className="text-end">
              <span role="button" className="d-inline-block p-1" onClick={closeSidebar}>
                <X size={24} />
              </span>
            </ListGroup.Item>
            {sidebarLinks}
            <ListGroup.Item as={NavLink} onClick={closeSidebar} to="/imprint">
              Imprint
            </ListGroup.Item>
            <ListGroup.Item as={NavLink} onClick={closeSidebar} to="/contact">
              Contact
            </ListGroup.Item>
            <ListGroup.Item as={NavLink} onClick={closeSidebar} to="/about">
              About
            </ListGroup.Item>
            {user !== null && user !== undefined && (
              <ListGroup.Item onClick={logout} role="button">
                Logout
              </ListGroup.Item>
            )}
          </ListGroup>
        </div>
      </Collapse>
    </Navbar>
  );
};
