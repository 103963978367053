import { isAxiosError } from "axios";
import { FC, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { login } from "src/api/user.ts";
import { setUser } from "src/store/auth.ts";
import { useAppDispatch } from "src/store/store.ts";

interface LoginFormData {
  email: string;
  password: string;
}

export const LoginPage: FC = () => {
  const location = useLocation();
  const { register, handleSubmit } = useForm<LoginFormData>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();

  const onSubmit = async (loginFormData: LoginFormData) => {
    try {
      const loginResponse = await login(loginFormData.email, loginFormData.password);
      dispatch(setUser(loginResponse));
      if (location.search.indexOf("backurl") >= 0) {
        const matches = location.search.match("backurl=([^&]*)");
        if (matches !== null) {
          navigate(decodeURIComponent(matches[1]));
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 403) {
          setError("Wrong username or password.");
        }
      } else {
        console.error(error);
      }
    }
  };

  return (
    <Row>
      <Col xs={12} sm={8} md={6} xl={4} className="m-auto mt-5">
        <div className="bg-white shadow p-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" {...register("email")} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" {...register("password")} />
            </Form.Group>
            {error ? <div className="d-flex text-danger">{error}</div> : null}
            <div className="d-flex align-items-center justify-content-between">
              <Link to="/password-reset">Reset password</Link>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
