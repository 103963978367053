import { debounce } from "lodash";
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Fade, Form, InputGroup, Spinner } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";

import { fetchSubscriptions } from "src/api/subscriptions.ts";
import { ColumnDropdown } from "src/components/columnDropdown/columnDropdown.tsx";
import { Pagination } from "src/components/pagination.tsx";
import { SubscriptionList, SubscriptionRow } from "src/components/subscriptionList/subscriptionList.tsx";
import { subscriptionColumns } from "src/components/subscriptionList/subscriptionListColumns.ts";
import { usePaginationContext } from "src/components/subscriptionList/subscriptionListPaginationContext.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { Subscription } from "src/model/subscription.ts";
import {
  loadSubscriptionColumnOrderSetting,
  loadSubscriptionColumnSetting,
  saveSubscriptionColumnOrderSetting,
  saveSubscriptionColumnSetting,
} from "src/utils/localStorage.ts";

import styles from "src/components/customerList.module.scss";

export const FilteredSubscriptionList: FC = () => {
  const [columns, setColumns] = useState<(keyof SubscriptionRow)[] | null>(null);
  const [columnOrder, setColumnOrder] = useState<(keyof SubscriptionRow)[] | null>(null);
  const [subscriptions, setSubscriptions] = useState<PaginatedResponse<Subscription> | undefined>();
  const [loading, setLoading] = useState(true);
  const { pagination, filter, setPagination } = usePaginationContext();
  const [search, setSearch] = useState(filter.query ?? "");
  // const user = useAuthState();

  useEffect(() => {
    setLoading(true);
    fetchSubscriptions({ excludeFailedSubscriptions: true, ...filter }, pagination)
      .then((response) => {
        setSubscriptions(response);
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => setLoading(false));
  }, [pagination, filter]);

  const onPageChange = (offset: number) => {
    setPagination((prevState) => ({ ...prevState, pagination: { ...prevState.pagination, offset: offset } }));
  };

  const onPageSizeChange = (limit: number) => {
    setPagination((prevState) => ({ ...prevState, pagination: { ...prevState.pagination, limit: limit } }));
  };

  useEffect(() => {
    const setting = loadSubscriptionColumnSetting();
    if (setting !== null) {
      setColumns(setting);
    } else {
      setColumns(subscriptionColumns.map((c) => c.key));
    }
  }, [setColumns]);

  useEffect(() => {
    const setting = loadSubscriptionColumnOrderSetting();
    if (setting !== null) {
      setColumnOrder(setting);
    } else {
      setColumnOrder(subscriptionColumns.map((c) => c.key));
    }
  }, [setColumnOrder]);

  useEffect(() => {
    if (columns !== null) {
      saveSubscriptionColumnSetting(columns);
    }
  }, [columns]);

  useEffect(() => {
    if (columnOrder !== null) {
      saveSubscriptionColumnOrderSetting(columnOrder);
    }
  }, [columnOrder]);

  const onSearch = useCallback(
    (search: string) => {
      const query = search.length > 0 ? search : undefined;
      setPagination((prevState) => ({ ...prevState, filter: { ...prevState.filter, query: query } }));
    },
    [setPagination]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(onSearch, 500);
  }, [onSearch]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);

    debouncedSearch(value);
  };

  // const onReportDownload = async () => {
  //   // showDialog("Download Subscription Report", "Body")
  //   const subscriptionReportRequest = await fetchSubscriptionReportRequest(
  //     { excludeFailedSubscriptions: true, ...filter },
  //     { sortBy: pagination.sortBy, sortDir: pagination.sortDir }
  //   );
  //
  //   window.open(
  //     `${config.apiUrl}/API/iSEA5/V2.1/Subscriptions/Reports/${subscriptionReportRequest.downloadToken}`,
  //     "_blank"
  //   );
  // };

  if (subscriptions === undefined || columns === null || columnOrder === null) {
    return null;
  }

  return (
    <>
      <div className="d-flex mb-3">
        <Col xl={6} xxl={4}>
          <InputGroup>
            <Form.Control value={search} onChange={onSearchChange} placeholder="Search" />
            <Button onClick={() => onSearch(search)} variant="outline-primary">
              <Search />
            </Button>
          </InputGroup>
        </Col>

        <div className="flex-grow-1" />

        {/*{user.role === "Tracking_Admin" && (*/}
        {/*  <Button onClick={onReportDownload} className="me-3">*/}
        {/*    Download As Excel*/}
        {/*  </Button>*/}
        {/*)}*/}

        <ColumnDropdown
          items={subscriptionColumns}
          selectedColumns={columns}
          columnOrder={columnOrder}
          setColumnOrder={(order: (keyof SubscriptionRow)[]) => setColumnOrder(order)}
          setSelectedColumns={(columns: (keyof SubscriptionRow)[]) => setColumns(columns)}
        />
      </div>
      <Fade in={loading} unmountOnExit={true} mountOnEnter={true}>
        <div className={`d-flex justify-content-center align-items-center ${styles.block}`}>
          <div className={`w-100 h-100 ${styles.fade}`}></div>
          <Spinner />
        </div>
      </Fade>
      <SubscriptionList
        subscriptions={subscriptions.items}
        columns={columnOrder.filter((c) => columns.indexOf(c) >= 0)}
      />
      <Pagination
        totalItems={subscriptions.totalItems}
        offset={pagination.offset}
        limit={pagination.limit}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </>
  );
};
