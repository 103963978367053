import { FC, useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { fetchCustomerSubscriptionStats, fetchCustomers } from "src/api/customers.ts";
import { SubscriptionStats } from "src/model/subscriptionStats.ts";
import { useAuthState } from "src/store/store.ts";

const StatsTable: FC<{ stats: SubscriptionStats }> = ({ stats }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th className="text-end">Subscriptions</th>
          <th className="text-end">Containers</th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        <tr>
          <td>All</td>
          <td className="text-end">{stats.totalSubscriptions}</td>
          <td className="text-end">{stats.totalContainers}</td>
        </tr>
        <tr>
          <td>Registered</td>
          <td className="text-end">{stats.registeredSubscriptions}</td>
          <td className="text-end">{stats.registeredContainers}</td>
        </tr>
        <tr>
          <td>Active</td>
          <td className="text-end">{stats.activeSubscriptions}</td>
          <td className="text-end">{stats.activeContainers}</td>
        </tr>
        <tr>
          <td>Started</td>
          <td className="text-end">{stats.startedSubscriptions}</td>
          <td className="text-end">{stats.startedContainers}</td>
        </tr>
        <tr>
          <td>ETA earlier</td>
          <td className="text-end">{stats.etaEarlierSubscriptions}</td>
          <td className="text-end">{stats.etaEarlierContainers}</td>
        </tr>
        <tr>
          <td>ETA in time</td>
          <td className="text-end">{stats.etaInTimeSubscriptions}</td>
          <td className="text-end">{stats.etaInTimeContainers}</td>
        </tr>
        <tr>
          <td>ETA later</td>
          <td className="text-end">{stats.etaLaterSubscriptions}</td>
          <td className="text-end">{stats.etaLaterContainers}</td>
        </tr>
        <tr>
          <td className="border-0">Done</td>
          <td className="border-0 text-end">{stats.doneSubscriptions}</td>
          <td className="border-0 text-end">{stats.doneContainers}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const CustomerDetails: FC<{ customerId: string; customerName?: string }> = ({ customerId, customerName }) => {
  const [stats, setStats] = useState<SubscriptionStats>();

  useEffect(() => {
    fetchCustomerSubscriptionStats(customerId).then(setStats);
  }, [customerId]);

  return (
    <div className="bg-white shadow">
      {customerName && <div className="d-flex justify-content-center fw-bold bg-dark-subtle p-3">{customerName}</div>}
      <div className="p-3 pb-1 text-uppercase fs-6">
        {stats ? <StatsTable stats={stats} /> : <div className="p-4 text-center">Loading...</div>}
      </div>
    </div>
  );
};

export const Dashboard: FC = () => {
  const user = useAuthState();
  const [customers, setCustomers] = useState<{ customerId: string; customerName?: string }[]>();

  useEffect(() => {
    if (user.role === "Tracking_Admin") {
      fetchCustomers({ offset: 0, limit: 20, sortBy: "Name", sortDir: "Asc" }).then((result) =>
        setCustomers(result.items.map((c) => ({ customerId: c.id, customerName: c.companyName })))
      );
    } else {
      const customerId = user.companyId ?? user.customerId;
      if (customerId) setCustomers([{ customerId: customerId }]);
    }
  }, [user]);

  if (!customers) {
    return null;
  }

  return (
    <div className="d-flex flex-wrap">
      {customers.map((c) => (
        <div key={`customer-${c.customerId}`} style={{ width: "440px" }} className="mx-4 my-3">
          <CustomerDetails customerId={c.customerId} customerName={c.customerName} />
        </div>
      ))}
    </div>
  );
};
