import { GET } from "src/api/api.ts";
import { ShippingLine } from "src/model/shippingLine.ts";

export const fetchShippingLines = async (): Promise<ShippingLine[]> => {
  const { data, error } = await GET("/API/iSEA5/V{version}/ShippingLines", {
    params: {
      path: {
        version: "2.1",
      },
    },
  });
  if (data !== undefined) {
    return data;
  }
  if (error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch shippinglines");
};
