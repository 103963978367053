import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { Auth, refreshToken } from "src/api/user.ts";
import { RootState, useAppDispatch } from "src/store/store.ts";

export type UserRole =
  | "Tracking_Nothing"
  | "Tracking_Admin"
  | "Tracking_Admin_Viewer"
  | "Tracking_Service"
  | "Tracking_Customer_Service_Reader"
  | "Tracking_Company_Service_Reader";

interface AccessToken {
  Name: string;
  Role: UserRole;
  UserId: string;
  CustomerId?: string;
  CompanyId?: string;
  exp: number;
}

export interface UserState {
  accessToken: string;
  expiry: number;
  refreshToken: string;
  username: string;
  role: UserRole;
  userId: number;
  customerId?: string;
  companyId?: string;
}

interface AuthState {
  user: UserState | null | undefined;
}

const initialState: AuthState = {
  user: undefined,
};

const parseAccessToken = (user: Auth): AccessToken => {
  return JSON.parse(atob(user.accessToken.split(".")[1])) as AccessToken;
};

const mapUserToState = (user: Auth): UserState => {
  const accessToken = parseAccessToken(user);

  return {
    accessToken: user.accessToken,
    refreshToken: user.refreshToken,
    role: accessToken.Role,
    username: user.userName,
    userId: user.userId,
    expiry: accessToken.exp,
    customerId: accessToken.CustomerId,
    companyId: accessToken.CompanyId,
  };
};

let interceptor: number | undefined;
const removeAxiosInterceptor = () => {
  if (interceptor !== undefined) {
    axios.interceptors.request.eject(interceptor);
  }
};

const setAxiosInterceptor = (accessToken: string) => {
  removeAxiosInterceptor();
  interceptor = axios.interceptors.request.use((config) => {
    config.headers.Authorization = "Bearer " + accessToken;
    return config;
  });
};

const isTokenExpired = (expiry: number): boolean => {
  return expiry * 1000 < Date.now() - 60000;
};

export const refreshAuth = async (dispatch: ReturnType<typeof useAppDispatch>, getState: () => RootState) => {
  const {
    auth: { user },
  } = getState();
  if (user === null || user === undefined) {
    return;
  }
  if (isTokenExpired(user.expiry)) {
    try {
      const auth = await refreshToken(user.refreshToken);
      dispatch(setUser(auth));
    } catch (error) {
      console.error("Unable to refresh accessToken", error);
    }
  }
};

export const initialize = async (dispatch: ReturnType<typeof useAppDispatch>) => {
  const authString = localStorage.getItem("authentication");

  if (authString === null) {
    dispatch(clearUser());
  } else {
    let user = JSON.parse(authString) as Auth;
    const accessToken = parseAccessToken(user);
    if (isTokenExpired(accessToken.exp)) {
      try {
        user = await refreshToken(user.refreshToken);
      } catch (error) {
        console.error(error);
        dispatch(clearUser());
        return;
      }
    }
    dispatch(setUser(user));
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (_, action: PayloadAction<Auth>) => {
      localStorage.setItem("authentication", JSON.stringify(action.payload));
      setAxiosInterceptor(action.payload.accessToken);

      return { user: mapUserToState(action.payload) };
    },
    clearUser: () => {
      localStorage.removeItem("authentication");
      removeAxiosInterceptor();
      return { user: null };
    },
  },
});

export const authReducer = authSlice.reducer;
// export const initialize = authSlice.actions.initialize;
export const setUser = authSlice.actions.setUser;
export const clearUser = authSlice.actions.clearUser;
