import { FC, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { requestPasswordReset } from "src/api/user.ts";

interface LoginFormData {
  email: string;
}

export const PasswordResetPage: FC = () => {
  const { register, handleSubmit } = useForm<LoginFormData>();
  const [isRequested, setIsRequested] = useState<boolean>(false);

  const onSubmit = async (loginFormData: LoginFormData) => {
    try {
      await requestPasswordReset(loginFormData.email);
      setIsRequested(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Row>
      <Col xs={12} sm={8} md={6} xl={4} className="m-auto mt-5">
        <div className="bg-white shadow p-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" {...register("email")} />
            </Form.Group>

            <div className="d-flex align-items-center justify-content-between">
              <div>{isRequested ? "You will receive an email with further instructions." : null}</div>
              <Button variant="primary" type="submit" disabled={isRequested}>
                Request new password
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
