import { ContainerRow } from "src/components/containerList/containerList.tsx";
import { SubscriptionRow } from "src/components/subscriptionList/subscriptionList.tsx";

// Containerlist
export const saveContainerColumnSetting = (columns: (keyof ContainerRow)[]) => {
  localStorage.setItem("containerColumnSetting", JSON.stringify(columns));
};

export const saveContainerColumnOrderSetting = (columns: (keyof ContainerRow)[]) => {
  localStorage.setItem("containerColumnOrderSetting", JSON.stringify(columns));
};

export const loadContainerColumnSetting = (): (keyof ContainerRow)[] | null => {
  const setting = localStorage.getItem("containerColumnSetting");
  if (setting === null) {
    return null;
  }
  return JSON.parse(setting);
};

export const loadContainerColumnOrderSetting = (): (keyof ContainerRow)[] | null => {
  const setting = localStorage.getItem("containerColumnOrderSetting");
  if (setting === null) {
    return null;
  }
  return JSON.parse(setting);
};

export const saveContainerPageSize = (pageSize: number) => {
  localStorage.setItem("containerPageSize", `${pageSize}`);
};

export const loadContainerPageSize = (): number | null => {
  const pageSize = localStorage.getItem("containerPageSize");
  if (pageSize === null) {
    return null;
  }
  return parseInt(pageSize);
};

// Subscriptionlist
export const saveSubscriptionColumnSetting = (columns: (keyof SubscriptionRow)[]) => {
  localStorage.setItem("subscriptionColumnSetting", JSON.stringify(columns));
};

export const loadSubscriptionColumnSetting = (): (keyof SubscriptionRow)[] | null => {
  const setting = localStorage.getItem("subscriptionColumnSetting");
  if (setting === null) {
    return null;
  }
  return JSON.parse(setting);
};

export const saveSubscriptionColumnOrderSetting = (columns: (keyof SubscriptionRow)[]) => {
  localStorage.setItem("subscriptionColumnOrderSetting", JSON.stringify(columns));
};

export const loadSubscriptionColumnOrderSetting = (): (keyof SubscriptionRow)[] | null => {
  const setting = localStorage.getItem("subscriptionColumnOrderSetting");
  if (setting === null) {
    return null;
  }
  return JSON.parse(setting);
};

export const saveSubscriptionPageSize = (pageSize: number) => {
  localStorage.setItem("subscriptionPageSize", `${pageSize}`);
};

export const loadSubscriptionPageSize = (): number | null => {
  const pageSize = localStorage.getItem("subscriptionPageSize");
  if (pageSize === null) {
    return null;
  }
  return parseInt(pageSize);
};
