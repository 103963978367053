import { FC } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { changePassword } from "src/api/user.ts";
import { useToasts } from "src/store/store.ts";

interface LoginFormData {
  password: string;
}

export const NewPasswordPage: FC = () => {
  const [searchParams] = useSearchParams();
  const { register, handleSubmit } = useForm<LoginFormData>();
  const tokenParam = searchParams.get("token");
  const navigate = useNavigate();
  const { showToast } = useToasts();

  const onSubmit = async (token: string, loginFormData: LoginFormData) => {
    try {
      await changePassword(token, loginFormData.password);
      showToast("Password successfully changed.");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Row>
      <Col xs={12} sm={8} md={6} xl={4} className="m-auto mt-5">
        <div className="bg-white shadow p-3">
          {tokenParam === null ? (
            "Missing parameter token"
          ) : (
            <Form onSubmit={handleSubmit((data) => onSubmit(tokenParam, data))}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" {...register("password")} />
              </Form.Group>

              <div className="d-flex align-items-center justify-content-between">
                <Button variant="primary" type="submit">
                  Reset password
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Col>
    </Row>
  );
};
