import { FC, useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { Footer } from "src/components/footer.tsx";
import { Navigation } from "src/components/navigation.tsx";
import { ContainerListPage } from "src/pages/containerListPage.tsx";
import { CustomerDetailsPage } from "src/pages/customerDetailsPage.tsx";
import { CustomerListPage } from "src/pages/customerListPage.tsx";
import { Home } from "src/pages/home.tsx";
import { JobListPage } from "src/pages/jobListPage.tsx";
import { LoginPage } from "src/pages/loginPage.tsx";
import { LoginWithTokenPage } from "src/pages/loginWithTokenPage.tsx";
import { MapPage } from "src/pages/mapPage.tsx";
import { NewPasswordPage } from "src/pages/newPasswordPage.tsx";
import { NotFoundPage } from "src/pages/notFoundPage.tsx";
import { PasswordResetPage } from "src/pages/passwordResetPage.tsx";
import { SubscriptionDetailsPage } from "src/pages/subscriptionDetailsPage.tsx";
import { SubscriptionListPage } from "src/pages/subscriptionListPage.tsx";
import { SubscriptionLogListPage } from "src/pages/subscriptionLogListPage.tsx";
import { UserDetailsPage } from "src/pages/userDetailsPage.tsx";
import { UserListPage } from "src/pages/userListPage.tsx";
import { UserRole, initialize, refreshAuth } from "src/store/auth.ts";
import { useAppDispatch, useAppSelector, useAuthState } from "src/store/store.ts";
import { useEffectOnce } from "src/utils/useEffectOnce.ts";

const Root: FC = () => {
  return (
    <>
      <Navigation />
      <Container fluid={true} className="p-4 overflow-y-scroll flex-grow-1">
        <Outlet />
      </Container>
      <Footer />
    </>
  );
};

const ProtectedRoute: FC<{ role: UserRole }> = ({ role }) => {
  const user = useAuthState();
  if (user.role === role) {
    return <Outlet />;
  }
  return <div>You are not allowed to view this page.</div>;
};

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/subscriptions",
        element: <SubscriptionListPage />,
      },
      {
        path: "/subscriptions/:subscriptionId",
        element: <SubscriptionDetailsPage />,
      },
      {
        path: "/map",
        element: <MapPage />,
      },
      {
        path: "/containers",
        element: <ContainerListPage />,
      },
      {
        path: "/",
        element: <ProtectedRoute role="Tracking_Admin" />,
        children: [
          {
            path: "/customers",
            element: <CustomerListPage />,
          },
          {
            path: "/customers/:customerId",
            element: <CustomerDetailsPage />,
          },
          {
            path: "/customers/:customerId/companies/:companyId",
            element: <CustomerDetailsPage />,
          },
          {
            path: "/users",
            element: <UserListPage />,
          },
          {
            path: "/users/:userId",
            element: <UserDetailsPage />,
          },
          {
            path: "/subscription-logs",
            element: <SubscriptionLogListPage />,
          },
          {
            path: "/jobs",
            element: <JobListPage />,
          },
        ],
      },
      {
        path: "/*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

const RedirectRoute: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token !== null) {
      const backurlSearchParams = createSearchParams(searchParams);
      backurlSearchParams.delete("token");
      searchParams.append("backurl", `${location.pathname}?${backurlSearchParams.toString()}`);
      navigate({ pathname: "/login-with-token", search: searchParams.toString() });
    } else {
      navigate(`/login?backurl=${encodeURIComponent(location.pathname + location.search)}`);
    }
  }, [navigate, location, searchParams]);

  return <div>Redirect route</div>;
};

const loginRouter = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/login-with-token",
        element: <LoginWithTokenPage />,
      },
      {
        path: "/password-reset",
        element: <PasswordResetPage />,
      },
      {
        path: "/new-password",
        element: <NewPasswordPage />,
      },
      {
        path: "/*",
        element: <RedirectRoute />,
      },
    ],
  },
]);

function App() {
  const { user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(initialize);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(refreshAuth);
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch]);

  if (user === undefined) {
    return null;
  }

  return <RouterProvider router={user === null ? loginRouter : router} />;
}

export default App;
