import { FC, useEffect, useState } from "react";

import { fetchSubscriptionLogs } from "src/api/subscriptions.ts";
import { Pagination } from "src/components/pagination.tsx";
import { SubscriptionLogList } from "src/components/subscriptionLogList/subscriptionLogList.tsx";
import { usePaginationContext } from "src/components/subscriptionLogList/subscriptionLogListPaginationContext.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { ShipmentRegistrationLog } from "src/model/subscriptionLog.ts";

export const FilteredSubscriptionLogList: FC = () => {
  const [subscriptionLogs, setSubscriptionLogs] = useState<PaginatedResponse<ShipmentRegistrationLog> | undefined>();
  const { pagination, filter, setPagination } = usePaginationContext();

  useEffect(() => {
    fetchSubscriptionLogs(pagination)
      .then((response) => {
        setSubscriptionLogs(response);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, [pagination, filter]);

  const onPageChange = (offset: number) => {
    setPagination((prevState) => ({ ...prevState, pagination: { ...prevState.pagination, offset: offset } }));
  };

  if (subscriptionLogs === undefined) {
    return null;
  }

  return (
    <>
      <SubscriptionLogList subscriptionLogs={subscriptionLogs.items} />
      <Pagination
        totalItems={subscriptionLogs.totalItems}
        offset={pagination.offset}
        limit={pagination.limit}
        onPageChange={onPageChange}
      />
    </>
  );
};
