import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { FilteredSubscriptionLogList } from "src/components/subscriptionLogList/filteredSubscriptionLogList.tsx";
import { Provider } from "src/components/subscriptionLogList/subscriptionLogListPaginationContext.ts";
import { PageRequest } from "src/model/pageRequest.ts";
import { SubscriptionLogFilter, SubscriptionLogPagination } from "src/model/subscriptionLog.ts";
import { parseSearchParamToNumber } from "src/utils/searchParams.ts";

const defaultPagination: PageRequest = {
  limit: 10,
  offset: 0,
};

const buildDefaultPagination = (
  searchParams: URLSearchParams
): { pagination: SubscriptionLogPagination; filter: SubscriptionLogFilter } => {
  const offset = parseSearchParamToNumber(searchParams.get("offset"));
  const query = searchParams.get("query") ?? undefined;

  return {
    pagination: {
      ...defaultPagination,
      offset: offset ?? defaultPagination.offset,
    },
    filter: {
      query: query,
    },
  };
};

export const SubscriptionLogListPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ filter, pagination }, setPaginationProps] = useState<{
    pagination: SubscriptionLogPagination;
    filter: SubscriptionLogFilter;
  }>(buildDefaultPagination(searchParams));

  useEffect(() => {
    setSearchParams((prev) => {
      Object.entries(pagination).forEach(([key, value]) => {
        if (value !== undefined) {
          prev.set(key, value);
        } else {
          prev.delete(key);
        }
      });
      // TODO Sync filter to url
      return prev;
    });
  }, [pagination, setSearchParams]);

  return (
    <Provider
      value={{
        pagination: pagination,
        filter: filter,
        setPagination: setPaginationProps,
      }}
    >
      <FilteredSubscriptionLogList />
    </Provider>
  );
};
