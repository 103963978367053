import { Dispatch, Provider, SetStateAction, createContext, useContext } from "react";

export interface PaginationContextProps<P, F> {
  pagination: P;
  filter: F;
  setPagination: Dispatch<SetStateAction<Omit<PaginationContextProps<P, F>, "setPagination">>>;
}

export const buildPaginationContext = <P, F>(): {
  Provider: Provider<PaginationContextProps<P, F> | null>;
  usePaginationContext: () => PaginationContextProps<P, F>;
} => {
  const PaginationContext = createContext<PaginationContextProps<P, F> | null>(null);

  const usePaginationContext = (): PaginationContextProps<P, F> => {
    const context = useContext(PaginationContext);
    if (context === null) {
      throw new Error("usePaginationContext must be within PaginationProvider");
    }
    return context;
  };

  return {
    Provider: PaginationContext.Provider,
    usePaginationContext: usePaginationContext,
  };
};
