import axios from "axios";

import { DELETE, GET, POST, PUT } from "src/api/api.ts";
import { config } from "src/config.ts";
import { PageRequest } from "src/model/pageRequest.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { User } from "src/model/user.ts";
import { UserSaveRequest } from "src/model/userSaveRequest.ts";

export interface Auth {
  accessToken: string;
  refreshToken: string;
  userName: string;
  userId: number;
}

export interface LoginResponse {
  data: Auth;
}

export const login = async (username: string, password: string): Promise<LoginResponse["data"]> => {
  return (
    await axios.post<LoginResponse>(`${config.apiUrl}/API/iSEA5/V2.0/Login`, {
      username: username,
      password: password,
    })
  ).data.data;
};

export const refreshToken = async (refreshToken: string): Promise<LoginResponse["data"]> => {
  return (
    await axios.post<LoginResponse>(`${config.apiUrl}/API/iSEA5/V2.0/RefreshAccessToken`, {
      refreshToken: refreshToken,
    })
  ).data.data;
};

export const requestPasswordReset = async (mail: string): Promise<void> => {
  await POST("/API/iSEA5/V{version}/Users/PasswordResetRequest", {
    params: {
      path: {
        version: "2.1",
      },
    },
    body: {
      mail: mail,
    },
  });
};

export const changePassword = async (token: string, password: string): Promise<void> => {
  await POST("/API/iSEA5/V{version}/Users/PasswordReset", {
    params: {
      path: {
        version: "2.1",
      },
    },
    body: {
      passwordChangeToken: token,
      password: password,
    },
  });
};

export const fetchUsers = async (pagination: PageRequest): Promise<PaginatedResponse<User>> => {
  const { data, error } = await GET("/API/iSEA5/V{version}/Users", {
    params: {
      path: {
        version: "2.1",
      },
      query: {
        limit: pagination.limit,
        offset: pagination.offset,
      },
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch users");
};

export const fetchUserDetails = async (userId: number): Promise<User> => {
  const { data, error } = await GET("/API/iSEA5/V{version}/Users/{userId}", {
    params: {
      path: {
        version: "2.1",
        userId: userId,
      },
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch user details");
};

export const createUser = async (userSaveRequest: UserSaveRequest): Promise<User> => {
  const { data, error } = await POST("/API/iSEA5/V{version}/Users", {
    params: {
      path: {
        version: "2.1",
      },
    },
    body: userSaveRequest,
  });

  if (data !== undefined) {
    return data;
  }
  if (error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to create user");
};

export const updateUser = async (userId: number, userSaveRequest: UserSaveRequest): Promise<User> => {
  const { data, error } = await PUT("/API/iSEA5/V{version}/Users/{userId}", {
    params: {
      path: {
        version: "2.1",
        userId: userId,
      },
    },
    body: userSaveRequest,
  });

  if (data !== undefined) {
    return data;
  }
  if (error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to update user");
};

export const deleteUser = async (userId: number) => {
  await DELETE("/API/iSEA5/V{version}/Users/{userId}", {
    params: {
      path: {
        version: "2.1",
        userId: userId,
      },
    },
  });
};
