import { Map as LeafletMap, icon } from "leaflet";
import type { LatLngBoundsExpression } from "leaflet";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Link, useSearchParams } from "react-router-dom";

import { fetchSubscriptionPositions } from "src/api/subscriptions.ts";
import { CompanyDropdown } from "src/components/companyDropdown.tsx";
import {
  buildContainerFilterParam,
  parseContainerFilterParam,
} from "src/components/containerList/containerFilterUtils.ts";
import { CustomerDropdown } from "src/components/customerDropdown.tsx";
import { SubscriptionFilterProps, SubscriptionPosition } from "src/model/subscription.ts";
import { useAuthState } from "src/store/store.ts";
import { dateToUtc } from "src/utils/datetime.ts";
import { formatDateTime } from "src/utils/timeFormat.ts";

import styles from "./map.module.scss";

const iconDefault = icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

const buildPopupInformation = (
  subscription: SubscriptionPosition,
  filter: SubscriptionFilterProps
): Record<string, string | number | null | ReactNode> => {
  return {
    "MBL Number":
      subscription.billOfLading !== null ? (
        <Link
          to={`/containers?filter=${buildContainerFilterParam({ query: subscription.billOfLading, customerId: filter.customerId, companyId: filter.companyId })}`}
        >
          {subscription.billOfLading}
        </Link>
      ) : null,
    "Booking Number": subscription.bookingNumber,
    "Port of Loading": subscription.portOfLoading,
    "Port of Discharge": subscription.portOfDischarge,
  };
};

const buildDefaultPagination = (searchParams: URLSearchParams): { filter: SubscriptionFilterProps } => {
  const filter = searchParams.get("filter");

  return {
    filter: parseContainerFilterParam(filter),
  };
};

export const MapPage: FC = () => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionPosition[]>([]);
  const mapRef = useRef<LeafletMap>(null);
  const user = useAuthState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ filter }, setPaginationProps] = useState<{
    filter: SubscriptionFilterProps;
  }>(buildDefaultPagination(searchParams));

  useEffect(() => {
    setSearchParams((prev) => {
      if (Object.keys(filter).length === 0) {
        prev.delete("filter");
      } else {
        prev.set("filter", buildContainerFilterParam(filter));
      }
      return prev;
    });
  }, [filter, setSearchParams]);

  useEffect(() => {
    if (user.role === "Tracking_Nothing" || filter.customerId !== undefined || filter.companyId !== undefined) {
      const today = new Date();
      const threeDaysAgo = dateToUtc(
        new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 3))
      );

      fetchSubscriptionPositions({
        excludeFailedSubscriptions: true,
        etaPortOfDischarge: [threeDaysAgo],
        ...filter,
      }).then((response) => setSubscriptions(response));
    }
  }, [filter, user]);

  const [markers, bounds] = useMemo((): [ReactNode[] | null, LatLngBoundsExpression | null] => {
    // TODO if this renders twice for filter changes

    const markers: ReactNode[] = [];
    let latitudeMax: number | undefined = undefined;
    let latitudeMin: number | undefined = undefined;
    let longitudeMax: number | undefined = undefined;
    let longitudeMin: number | undefined = undefined;
    for (const subscription of subscriptions) {
      const longitude = subscription.longitude;
      const latitude = subscription.latitude;

      latitudeMax = Math.max(latitudeMax || latitude, latitude);
      longitudeMax = Math.max(longitudeMax || longitude, longitude);
      latitudeMin = Math.min(latitudeMin || latitude, latitude);
      longitudeMin = Math.min(longitudeMin || longitude, longitude);

      markers.push(
        <Marker position={[latitude, longitude]} key={subscription.id} icon={iconDefault}>
          <Popup className={styles.popup}>
            {subscription.vesselName !== null && subscription.vesselOperationStatus !== null ? (
              <div className="border-bottom mb-2 pb-1">
                <div className="fw-bold fs-5">{subscription.vesselName}</div>
                <div>{subscription.vesselOperationStatus}</div>
              </div>
            ) : null}
            {Object.entries(buildPopupInformation(subscription, filter))
              .filter(([, value]) => value !== null)
              .map(([key, value]) => (
                <div className="mb-1" key={key}>
                  <span className="fw-bold">{key}:</span> {value}
                </div>
              ))}
            {subscription.etaPortOfDischarge !== null ? (
              <div className="mb-1">
                <span className="fw-bold">ETA:</span>{" "}
                <span className={subscription.dischargeDelayed === true ? "text-danger" : ""}>
                  {formatDateTime(subscription.etaPortOfDischarge)}
                </span>
              </div>
            ) : null}
          </Popup>
        </Marker>
      );
    }

    if (
      markers.length === 0 ||
      latitudeMax === undefined ||
      latitudeMin === undefined ||
      longitudeMax === undefined ||
      longitudeMin === undefined
    ) {
      return [null, null];
    }

    return [
      markers,
      [
        [latitudeMax, longitudeMax],
        [latitudeMin, longitudeMin],
      ],
    ];
  }, [subscriptions, filter]);

  useEffect(() => {
    if (bounds !== null) {
      mapRef.current?.fitBounds(bounds);
    }
  }, [bounds]);

  const displayMap = useMemo(() => {
    return (
      <MapContainer
        zoom={3}
        center={[0, 0]}
        scrollWheelZoom={true}
        style={{ width: "100%" }}
        className="flex-grow-1"
        maxZoom={10}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
        />
        {markers}
      </MapContainer>
    );
  }, [markers]);

  const onChangeCustomer = (customerId: string | undefined) => {
    setPaginationProps((prevState) => ({
      filter: { ...prevState.filter, customerId: customerId, companyId: undefined },
    }));
  };

  const onChangeCompany = (companyId: string | undefined) => {
    setPaginationProps((prevState) => ({
      filter: { ...prevState.filter, companyId: companyId },
    }));
  };

  if (user === null || user === undefined) {
    return null;
  }

  return (
    <div className="h-100 d-flex flex-column">
      {user.role === "Tracking_Admin" ? (
        <Row className="mb-3">
          <Col xs={2}>
            <CustomerDropdown
              onChangeCustomerId={onChangeCustomer}
              customerId={filter.customerId}
              allowDeselect={false}
            />
          </Col>
          <Col xs={2}>
            <CompanyDropdown
              onChangeCompanyId={onChangeCompany}
              customerId={filter.customerId}
              companyId={filter.companyId}
            />
          </Col>
        </Row>
      ) : null}
      {displayMap}
    </div>
  );
};
