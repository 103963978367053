import { ChangeEvent, FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { fetchCustomers } from "src/api/customers.ts";
import { Customer } from "src/model/customer.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";

interface CustomerDropdownProps {
  onChangeCustomerId: (customerId: string | undefined) => void;
  customerId: string | undefined;
  allowDeselect: boolean;
  className?: string;
  size?: "sm" | "lg";
}

export const CustomerDropdown: FC<CustomerDropdownProps> = ({
  onChangeCustomerId,
  customerId,
  allowDeselect,
  className,
  size,
}) => {
  const [customers, setCustomers] = useState<PaginatedResponse<Customer> | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchCustomers({ offset: 0, limit: 20, sortBy: "Name", sortDir: "Asc" })
      .then((result) => setCustomers(result))
      .finally(() => setLoading(false));
  }, []);

  const onChangeItem = (event: ChangeEvent<HTMLSelectElement>) => {
    const customerId = event.target.value;
    if (customerId !== "") {
      onChangeCustomerId(customerId);
    } else {
      onChangeCustomerId(undefined);
    }
  };

  const buildItems = () => {
    if (customers === undefined || loading) {
      return <option value="">Loading ...</option>;
    }

    return (
      <>
        {allowDeselect || customerId === undefined ? <option value="">Select Customer</option> : null}
        {customers.items.map((c) => (
          <option key={`customer-${c.id}`} value={c.id}>
            {c.companyName}
          </option>
        ))}
      </>
    );
  };

  return (
    <Form.Select
      disabled={loading}
      onChange={onChangeItem}
      value={loading ? "" : customerId ?? ""}
      className={className}
      size={size}
    >
      {buildItems()}
    </Form.Select>
  );
};
