import { FC, useEffect, useState } from "react";
import { Fade, Spinner, Table } from "react-bootstrap";
import { Check, Pencil, PlusCircle, Trash, X } from "react-bootstrap-icons";
import { Link, useSearchParams } from "react-router-dom";

import { deleteUser, fetchUsers } from "src/api/user.ts";
import { ConfirmModal } from "src/components/confirmModal.tsx";
import { Pagination } from "src/components/pagination.tsx";
import { PageRequest } from "src/model/pageRequest.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { User } from "src/model/user.ts";
import { useAuthState } from "src/store/store.ts";
import { parseSearchParamToNumber } from "src/utils/searchParams.ts";

import styles from "./customerList.module.scss";

const defaultPagination: PageRequest = {
  limit: 10,
  offset: 0,
};

const buildDefaultPagination = (searchParams: URLSearchParams): PageRequest => {
  const offset = parseSearchParamToNumber(searchParams.get("offset"));

  return {
    ...defaultPagination,
    offset: offset ?? defaultPagination.offset,
  };
};

export const UserList: FC = () => {
  const [users, setUsers] = useState<PaginatedResponse<User> | undefined>();
  const [userToDelete, setUserToDelete] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<PageRequest>(buildDefaultPagination(searchParams));
  const user = useAuthState();

  useEffect(() => {
    setSearchParams((prev) => {
      Object.entries(pagination).forEach(([key, value]) => {
        if (value !== undefined) {
          prev.set(key, value);
        } else {
          prev.delete(key);
        }
      });
      return prev;
    });
  }, [pagination, setSearchParams]);

  useEffect(() => {
    setLoading(true);
    fetchUsers(pagination)
      .then((result) => setUsers(result))
      .finally(() => setLoading(false));
  }, [pagination]);

  const confirmDeletion = async () => {
    if (userToDelete === undefined) {
      return;
    }
    await deleteUser(userToDelete.id);
    setUserToDelete(undefined);
    if (users && users.totalItems > 1) {
      setPagination((prevState) => ({ ...prevState }));
    } else if (pagination.offset > 0) {
      setPagination((prevState) => ({
        ...prevState,
        limit: prevState.limit,
        offset: prevState.offset - prevState.limit,
      }));
    } else {
      setPagination((prevState) => ({ ...prevState, limit: prevState.limit, offset: 0 }));
    }
  };

  const onPageChange = (offset: number) => {
    setPagination((prevState) => ({ ...prevState, offset: offset }));
  };

  if (users === undefined) {
    return (
      <div className={`d-flex justify-content-center align-items-center ${styles.block}`}>
        <div className={`w-100 h-100 ${styles.fade}`}></div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Fade in={loading} unmountOnExit={true} mountOnEnter={true}>
        <div className={`d-flex justify-content-center align-items-center ${styles.block}`}>
          <div className={`w-100 h-100 ${styles.fade}`}></div>
          <Spinner />
        </div>
      </Fade>
      <ConfirmModal
        title={`Delete user ${userToDelete?.userName}`}
        body={`Do you really want to delete user '${userToDelete?.userName}'?`}
        show={userToDelete !== undefined}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        handleConfirm={confirmDeletion}
        handleClose={() => setUserToDelete(undefined)}
      />
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Active</th>
            <th>Mail</th>
            <th>Role</th>
            <th>Customer</th>
            <th>Company</th>
            <th style={{ width: "64px" }} className="text-end">
              <Link to="/users/new" className="text-black">
                <PlusCircle size={18} />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.items.map((u) => (
            <tr key={`user-${u.id}`}>
              <td>{u.userName}</td>
              <td>{u.active ? <Check size={24} /> : <X size={24} />}</td>
              <td>{u.mail}</td>
              <td>{u.userRole}</td>
              <td>{u.customerName}</td>
              <td>{u.companyName}</td>
              <td>
                <div className="d-flex align-items-center justify-content-between">
                  <Link to={`/users/${u.id}`} className="text-black">
                    <Pencil size={18} />
                  </Link>
                  {user.userId !== u.id ? (
                    <span role="button" onClick={() => setUserToDelete(u)}>
                      <Trash size={18} />
                    </span>
                  ) : (
                    <span className="text-secondary">
                      <Trash size={18} />
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        totalItems={users.totalItems}
        offset={pagination.offset}
        limit={pagination.limit}
        onPageChange={onPageChange}
      />
    </>
  );
};
