import { isAxiosError } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { refreshToken } from "src/api/user.ts";
import { setUser } from "src/store/auth.ts";
import { useAppDispatch } from "src/store/store.ts";

export const LoginWithTokenPage: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string>();

  const loginWithToken = useCallback(
    async (token: string, backurl: string | null) => {
      try {
        const loginResponse = await refreshToken(decodeURIComponent(token));
        dispatch(setUser(loginResponse));
        if (backurl !== null) {
          navigate(decodeURIComponent(backurl), { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response?.status === 403) {
            setError("Unable to login with provided token.");
          }
        } else {
          console.error(error);
        }
      }
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    const token = searchParams.get("token");
    if (token !== null) {
      loginWithToken(token, searchParams.get("backurl"));
    }
  }, [searchParams, loginWithToken]);

  return <div>{error}</div>;
};
