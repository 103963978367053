import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { fetchShippingLines } from "src/api/shippingLines.ts";
import { fetchSubscription } from "src/api/subscriptions.ts";
import { SubscriptionForm } from "src/components/subscriptionForm.tsx";
import { ShippingLine } from "src/model/shippingLine.ts";
import { SimpleSubscription } from "src/model/subscription.ts";

export const SubscriptionDetailsPage: React.FC = () => {
  const { subscriptionId } = useParams();
  const [subscription, setSubscription] = useState<SimpleSubscription | null | undefined>();
  const [shippingLines, setShippingLines] = useState<ShippingLine[]>();

  useEffect(() => {
    fetchShippingLines().then(setShippingLines);
  }, [setShippingLines]);

  useEffect(() => {
    if (subscriptionId !== undefined) {
      if (subscriptionId === "new") {
        setSubscription(null);
      } else {
        fetchSubscription(subscriptionId).then((subscription) => {
          setSubscription(subscription);
        });
      }
    }
  }, [subscriptionId]);

  if (subscription === undefined || shippingLines === undefined) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Tracking - New Subscription</title>
      </Helmet>
      <SubscriptionForm subscription={subscription} shippingLines={shippingLines} />
    </>
  );
};
