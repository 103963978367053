import { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { fetchContainerFilterOptions } from "src/api/containers.ts";
import {
  buildContainerFilterParam,
  parseContainerFilterParam,
} from "src/components/containerList/containerFilterUtils.ts";
import { Provider, usePaginationContext } from "src/components/containerList/containerListPaginationContext.ts";
import { FilteredContainerList } from "src/components/containerList/filteredContainerList.tsx";
import { CompanyFilter } from "src/components/filter/companyFilter.tsx";
import { CustomerFilter } from "src/components/filter/customerFilter.tsx";
import { FilterItem } from "src/components/filter/filterItem.tsx";
import { RangeFilter, RangeFilterQuickOption } from "src/components/filter/rangeFilter.tsx";
import {
  ContainerFilterOptions,
  ContainerFilterProps,
  ContainerPagination,
  ContainerSortBy,
} from "src/model/container.ts";
import { useAuthState } from "src/store/store.ts";
import { dateToUtc, getStartOfQuarter } from "src/utils/datetime.ts";
import { loadContainerPageSize, saveContainerPageSize } from "src/utils/localStorage.ts";
import { buildPortString } from "src/utils/ports.ts";
import { parseSearchParamToNumber } from "src/utils/searchParams.ts";

const defaultPagination: ContainerPagination = {
  limit: 20,
  offset: 0,
  sortBy: "EtaPortOfDischarge",
  sortDir: "Desc",
};

interface FilterAndPagination {
  pagination: ContainerPagination;
  filter: ContainerFilterProps;
}

const buildDefaultPagination = (searchParams: URLSearchParams): FilterAndPagination => {
  const filter = searchParams.get("filter");
  const offset = parseSearchParamToNumber(searchParams.get("offset")) ?? defaultPagination.offset;
  const limit =
    parseSearchParamToNumber(searchParams.get("limit")) ?? loadContainerPageSize() ?? defaultPagination.limit;
  const sortBy = (searchParams.get("sortBy") as ContainerSortBy) ?? defaultPagination.sortBy;
  const sortDir = (searchParams.get("sortDir") as ContainerPagination["sortDir"]) ?? defaultPagination.sortDir;

  return {
    pagination: {
      offset: offset,
      limit: limit,
      sortDir: sortDir,
      sortBy: sortBy,
    },
    filter: parseContainerFilterParam(filter),
  };
};

export const ContainerListPage: FC = () => {
  const user = useAuthState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ filter, pagination }, setPaginationProps] = useState<FilterAndPagination>(
    buildDefaultPagination(searchParams)
  );

  useEffect(() => {
    saveContainerPageSize(pagination.limit);
  }, [pagination.limit]);

  useEffect(() => {
    setSearchParams((prev) => {
      Object.entries(pagination).forEach(([key, value]) => {
        if (value !== undefined) {
          prev.set(key, value);
        } else {
          prev.delete(key);
        }
      });
      if (Object.values(filter).filter((o) => o !== undefined).length === 0) {
        prev.delete("filter");
      } else {
        prev.set("filter", buildContainerFilterParam(filter));
      }
      return prev;
    });
  }, [filter, pagination, setSearchParams]);

  useEffect(() => {
    if (user.role === "Tracking_Nothing") {
      if (user.companyId !== undefined && user.customerId !== undefined) {
        setPaginationProps((prevstate) => ({
          pagination: {
            ...defaultPagination,
          },
          filter: {
            ...prevstate.filter,
            customerId: user.customerId,
            companyId: user.companyId,
          },
        }));
      } else if (user.customerId !== undefined) {
        setPaginationProps((prevstate) => ({
          pagination: {
            ...defaultPagination,
          },
          filter: {
            ...prevstate.filter,
            customerId: user.customerId,
          },
        }));
      }
    }
  }, [user]);

  return (
    <Provider
      value={{
        pagination: pagination,
        filter: filter,
        setPagination: setPaginationProps,
      }}
    >
      <div className="d-flex" style={{ minHeight: "100%" }}>
        <div className="ms-n4 p-4 mt-n4 mb-n4 border-end border-secondary-subtle">
          <div style={{ width: "220px" }}>
            <ContainerFilter />
          </div>
        </div>

        <div className="flex-grow-1 ms-4">
          <FilteredContainerList />
        </div>
      </div>
    </Provider>
  );
};

const ContainerFilter: FC = () => {
  const user = useAuthState();
  const [filterOptions, setFilterOptions] = useState<ContainerFilterOptions>();
  const { filter, setPagination } = usePaginationContext();

  useEffect(() => {
    fetchContainerFilterOptions(filter).then(setFilterOptions);
  }, [filter]);

  const setFilter = <K extends keyof ContainerFilterProps>(key: K) => {
    return (value: ContainerFilterProps[K]) => {
      setPagination((prevState) => {
        const newFilter: ContainerFilterProps = { ...prevState.filter };
        if (
          (Array.isArray(value) && value.length === 0) ||
          value === undefined ||
          (typeof value === "string" && value === "")
        ) {
          delete newFilter[key];
        } else {
          newFilter[key] = value;
        }

        return {
          ...prevState,
          filter: { ...newFilter },
          pagination: { ...prevState.pagination, offset: 0 },
        };
      });
    };
  };

  if (filterOptions === undefined) {
    return null;
  }

  const filterItems: ReactNode[] = [];
  if (user.role === "Tracking_Admin") {
    filterItems.push(
      <CustomerFilter onChangeCustomerId={setFilter("customerId")} selectedCustomerId={filter.customerId} />,
      <CompanyFilter
        onChangeCompanyId={setFilter("companyId")}
        selectedCompanyId={filter.companyId}
        selectedCustomerId={filter.customerId}
      />
    );
  }

  const buildEtaQuickOptions = (): RangeFilterQuickOption[] => {
    const today = new Date();

    const startOfCurrentQuarter = getStartOfQuarter(today);
    const startOfLastQuarter = dateToUtc(
      new Date(startOfCurrentQuarter.getUTCFullYear(), startOfCurrentQuarter.getUTCMonth() - 3, 1)
    );
    const endOfLastQuarter = dateToUtc(
      new Date(startOfCurrentQuarter.getUTCFullYear(), startOfCurrentQuarter.getUTCMonth(), 0)
    );

    return [
      {
        label: "Current Month",
        range: [
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1))),
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0))),
        ],
      },
      {
        label: "Last Month",
        range: [
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - 1, 1))),
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 0))),
        ],
      },
      {
        label: "Last Quarter",
        range: [startOfLastQuarter, endOfLastQuarter],
      },
      {
        label: "This year",
        range: [dateToUtc(new Date(today.getUTCFullYear(), 0, 1)), undefined],
      },
      {
        label: "Upcoming",
        range: [
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()))),
          undefined,
        ],
      },
    ];
  };

  if (
    filterOptions.portOfLoadingCodes.length > 0 ||
    (filter.portOfLoadingCodes && filter.portOfLoadingCodes.length > 0)
  ) {
    filterItems.push(
      <FilterItem
        title="Port of Loading"
        filterOptions={filterOptions.portOfLoadingCodes}
        selectedFilterOptions={filter.portOfLoadingCodes || []}
        onChangeFilter={setFilter("portOfLoadingCodes")}
        valueMapper={(filter) => buildPortString(filter.value, filter.key)}
      />
    );
  }
  if (
    filterOptions.portOfDischargeCodes.length > 0 ||
    (filter.portOfDischargeCodes && filter.portOfDischargeCodes.length > 0)
  ) {
    filterItems.push(
      <FilterItem
        title="Port of Discharge"
        filterOptions={filterOptions.portOfDischargeCodes}
        selectedFilterOptions={filter.portOfDischargeCodes || []}
        onChangeFilter={setFilter("portOfDischargeCodes")}
        valueMapper={(filter) => buildPortString(filter.value, filter.key)}
      />
    );
  }
  if (filterOptions.scacs.length > 0 || (filter.scacs && filter.scacs.length > 0)) {
    filterItems.push(
      <FilterItem
        title="Shipping Line"
        filterOptions={filterOptions.scacs}
        selectedFilterOptions={filter.scacs || []}
        onChangeFilter={setFilter("scacs")}
        valueMapper={(filter) => `${filter.value} (${filter.key})`}
      />
    );
  }

  filterItems.push(
    <RangeFilter
      title="Eta Port of Discharge"
      selectedDate={filter.etaPortOfDischarge}
      onChangeDate={setFilter("etaPortOfDischarge")}
      quickOptions={buildEtaQuickOptions()}
    />
  );

  if (filterOptions.status.length > 0 || (filter.status && filter.status.length > 0)) {
    filterItems.push(
      <FilterItem
        title="Status"
        filterOptions={filterOptions.status}
        selectedFilterOptions={filter.status ? [filter.status] : []}
        onChangeFilter={(values) => setFilter("status")(values[0])}
        radio={true}
        expandable={false}
      />
    );
  }

  // filterItems.push(
  //   <BoolFilter
  //     title="Status"
  //     onChangeFilter={setFilter("status")}
  //     filterOption={filterOptions.done}
  //     selected={filter.includeStatusDone}
  //   />
  // );

  return (
    <>
      <h4 className="mb-4 mt-2">Filter</h4>
      {filterItems.map((item, index) => (
        <Fragment key={`filter-${index}`}>
          {index > 0 && <div className="border-bottom mb-3 mt-3" />}
          {item}
        </Fragment>
      ))}
    </>
  );
};
