import { FC, Fragment, MouseEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { Collapse, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { CaretDownFill, CaretUpFill, InfoCircle, Pencil, PlusCircle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { CopyableText } from "src/components/copyableText.tsx";
import { ContainerList } from "src/components/subscriptionList/containerList.tsx";
import { DaysOfDelay } from "src/components/subscriptionList/daysOfDelay.tsx";
import { subscriptionColumns } from "src/components/subscriptionList/subscriptionListColumns.ts";
import { usePaginationContext } from "src/components/subscriptionList/subscriptionListPaginationContext.ts";
import { HeaderColumn } from "src/components/table/headerColumn.tsx";
import { Container, Subscription, SubscriptionSortBy } from "src/model/subscription.ts";
import { useAppSelector } from "src/store/store.ts";
import { iseaReferenceLink } from "src/utils/externalReferences.ts";
import { buildPortString } from "src/utils/ports.ts";
import { formatDate, formatDateTime } from "src/utils/timeFormat.ts";

interface SubscriptionListProps {
  subscriptions: Subscription[];
  columns: (keyof SubscriptionRow)[];
}

export interface SubscriptionRow {
  id: string;
  done: boolean;
  shippingLine: string;
  daysOfDelay: ReactNode;
  billOfLading: ReactNode;
  bookingNumber: ReactNode;
  externalReference: ReactNode;
  shippersReference: ReactNode;
  portOfLoading: string | null;
  etdPortOfLoading: string | null;
  portOfDischarge: string | null;
  etaPortOfDischarge: ReactNode;
  containers: Container[];
}

const iseaReferenceRegex = new RegExp("^\\w{1,3}-(\\w{1,3}-)?\\d{4}-\\d{5}$");

export const SubscriptionList: FC<SubscriptionListProps> = ({ subscriptions, columns }) => {
  const { user } = useAppSelector((state) => state.auth);
  const [openedSubscriptions, setOpenedSubscriptions] = useState<string[]>([]);
  const { pagination, setPagination } = usePaginationContext();

  useEffect(() => {
    setOpenedSubscriptions([]);
  }, [subscriptions]);

  const toggleSubscriptionState = (event: MouseEvent<HTMLTableRowElement>, subscriptionId: string) => {
    if (!(event.target instanceof HTMLAnchorElement) && !document.getSelection()?.toString())
      setOpenedSubscriptions((prevState) => {
        const index = prevState.indexOf(subscriptionId);
        if (index >= 0) {
          return prevState.toSpliced(index, 1);
        } else {
          return [...prevState, subscriptionId];
        }
      });
  };

  const buildExternalReferenceList = (referenceList: (string | null)[]): ReactNode => {
    const items: ReactNode[] = [];
    for (const referenceListElement of referenceList) {
      if (referenceListElement === null) {
        continue;
      }

      if (items.length > 0) {
        items.push(<br key={`reference-break-${items.length}`} />);
      }

      if (iseaReferenceRegex.test(referenceListElement)) {
        items.push(
          <a href={iseaReferenceLink(referenceListElement)} target="_blank" key={`reference-${items.length}`}>
            <CopyableText text={referenceListElement} />
          </a>
        );
      } else {
        items.push(
          <span key={`reference-${items.length}`}>
            <CopyableText text={referenceListElement} />
          </span>
        );
      }
    }

    return <>{items}</>;
  };

  const buildEtaPortOfDischarge = (subscription: Subscription): ReactNode => {
    if (subscription.etaPortOfDischargeHistory.length > 0) {
      return (
        <OverlayTrigger
          placement="right"
          delay={250}
          overlay={
            <Tooltip>
              {subscription.etaPortOfDischargeHistory.map((h, index) => (
                <div key={`history-${index}`} className="mb-2 text-start">
                  <b>ETA: {formatDateTime(h.arrival)}</b>
                  <br />
                  <small>reported on {formatDate(h.validAt)}</small>
                </div>
              ))}
            </Tooltip>
          }
        >
          <span className="d-inline-flex align-items-center">
            {formatDateTime(subscription.etaPortOfDischarge)}&nbsp;&nbsp;
            <InfoCircle />
          </span>
        </OverlayTrigger>
      );
    } else {
      return (
        <span className="d-inline-flex align-items-center">{formatDateTime(subscription.etaPortOfDischarge)}</span>
      );
    }
  };

  const buildShippersReferenceList = (subscription: Subscription): ReactNode => {
    return (
      <div>
        {subscription.metaData
          .map((md) => md.shippersReference)
          .filter((r) => r !== null)
          .map((sr, index) => (
            <div key={`sr-${index}`}>
              <CopyableText text={sr} />
            </div>
          ))}
      </div>
    );
  };

  const subscriptionRows: SubscriptionRow[] = useMemo(() => {
    return subscriptions.map(
      (s): SubscriptionRow => ({
        id: s.id,
        done: s.done,
        shippingLine: `${s.shippingLineName} (${s.scac})`,
        daysOfDelay: <DaysOfDelay days={s.daysOfDelay} />,
        billOfLading: <CopyableText text={s.billOfLading} />,
        bookingNumber: <CopyableText text={s.bookingNumber} />,
        portOfLoading: buildPortString(s.portOfLoading, s.portOfLoadingCode),
        portOfDischarge: buildPortString(s.portOfDischarge, s.portOfDischargeCode),
        shippersReference: buildShippersReferenceList(s),
        externalReference: buildExternalReferenceList(s.metaData.map((md) => md.externalReference)),
        etaPortOfDischarge: buildEtaPortOfDischarge(s),
        etdPortOfLoading: formatDateTime(s.etdPortOfLoading),
        containers: s.containers,
      })
    );
  }, [subscriptions]);

  const onSortChange = (sortKey: SubscriptionSortBy | undefined) => {
    if (sortKey === undefined) {
      return;
    }

    if (pagination.sortBy === sortKey) {
      setPagination((prevState) => ({
        ...prevState,
        pagination: { ...prevState.pagination, sortDir: prevState.pagination.sortDir === "Asc" ? "Desc" : "Asc" },
      }));
    } else {
      setPagination((prevState) => ({
        ...prevState,
        pagination: { ...prevState.pagination, sortBy: sortKey, sortDir: "Asc" },
      }));
    }
  };

  if (subscriptionRows.length === 0) {
    return <div>No subscriptions found.</div>;
  }

  const orderedAndFilteredColumns = subscriptionColumns
    .filter((cc) => columns.includes(cc.key))
    .toSorted((a, b) => columns.indexOf(a.key) - columns.indexOf(b.key));

  return (
    <Table className="align-middle">
      <thead>
        <tr>
          {orderedAndFilteredColumns.map((c) => (
            <th key={`th-${c.key}`}>
              <HeaderColumn
                value={c.value}
                sortKey={c.sortKey}
                onSortChange={onSortChange}
                selectedSortKey={pagination.sortBy}
                selectedSortDir={pagination.sortDir}
              />
            </th>
          ))}
          {user && user.role === "Tracking_Admin" ? (
            <th style={{ width: "64px" }} className="text-end">
              <Link to="/subscriptions/new" className="text-black">
                <PlusCircle size={18} />
              </Link>
            </th>
          ) : (
            <th />
          )}
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {subscriptionRows.map((sr, index) => (
          <Fragment key={`subscription-${index}`}>
            <tr onClick={(event) => toggleSubscriptionState(event, sr.id)}>
              {orderedAndFilteredColumns.map((column) => (
                <td key={`td-${column.key}`} className={sr.done ? "text-body-tertiary" : undefined}>
                  {sr[column.key]}
                </td>
              ))}
              <td>
                <div className="d-flex align-items-center justify-content-between">
                  {sr.containers.length > 0 ? (
                    openedSubscriptions.includes(sr.id) ? (
                      <CaretUpFill role="button" />
                    ) : (
                      <CaretDownFill role="button" />
                    )
                  ) : (
                    <div />
                  )}
                  {user && user.role === "Tracking_Admin" ? (
                    <Link to={`/subscriptions/${sr.id}`} className="text-black">
                      <Pencil size={18} />
                    </Link>
                  ) : (
                    <div />
                  )}
                </div>
              </td>
            </tr>
            {sr.containers.length > 0 && (
              <tr>
                <td colSpan={columns.length + 1} className="p-0 border-bottom-0">
                  <Collapse in={openedSubscriptions.includes(sr.id)} mountOnEnter={true} unmountOnExit={true}>
                    <div className="border-bottom">
                      <div className="m-3">
                        <ContainerList containers={sr.containers} />
                      </div>
                    </div>
                  </Collapse>
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
};
