import createClient, { Middleware } from "openapi-fetch";

import { paths } from "src/api/schema";
import { config } from "src/config.ts";
import { RootState } from "src/store/store.ts";

const client = createClient<paths>({
  baseUrl: config.apiUrl,
});

export const setupClient = (getState: () => RootState) => {
  const authMiddleware: Middleware = {
    async onRequest(req) {
      const user = getState().auth.user;
      if (user) {
        req.headers.set("Authorization", "Bearer " + user.accessToken);
      }
      return req;
    },
  };

  client.use(authMiddleware);
};

export const { GET, POST, PUT, DELETE } = client;
